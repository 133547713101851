<template>
    <card>
      <body-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('globalTrans.details') }}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col sm="12">
                <b-tabs content-class="mt-0" fill>
                  <b-tab :title="$t('license_management.application')" active>
                    <div v-if="loading">
                      <Loading />
                    </div>
                    <b-overlay v-else>
                      <div class="p-3">
                        <b-row>
                          <b-col sm="12">
                            <div class="text-right">
                              <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                              </b-button>
                            </div>
                            <div>
                              <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                                <div class="" style="width:50%"><b>{{ $t('license_management.agreement_no') }}</b> : {{
                                  appDetail.application.app_id }}</div>
                                <div class="text-right" style="width:50%"><b>{{ $t('externalLrcpn.issue_date') }}</b> : {{
                                  appDetail.application.issue_date | dateFormat }}</div>
                              </div>
                              <div class="stepper-wrapper">
                                <div :class="item.value <= appDetail.application.status ? `stepper-item completed` : `stepper-item`"
                                  v-for="(item, index) in stepList" :key="index">
                                  <div class="step-counter">
                                    <i v-if="item.value <= appDetail.application.status" class="ri-check-fill"></i>
                                    <span v-else>{{ $n(index+1) }}</span>
                                  </div>
                                  <div class="step-name">{{ item.text }}</div>
                                </div>
                              </div>
                              <!-- Application view start -->
                              <div class="application-form-wrapper application-form-view-wrapper mt-5">
                                <div class="application-itmes">
                                  <div class="group-form-card">
                                    <!-- application headline -->
                                    <div v-if="currentLocale === 'bn'">
                                      বরাবর <br>
                                      উপপরিচালক (বাণিজ্য)<br>
                                      বাংলাদেশ চা বোর্ড<br>
                                      ১৭১-১৭২, বায়েজিদ বোস্তামি রোড,<br>
                                      নাসিরাবাদ, চট্টগ্রাম।<br><br>
                                      <!-- subject -->
                                      বিষয়: নিলাম থেকে চা ক্রয় করে রপ্তানির জন্য লাইসেন্সের আবেদন প্রসঙ্গে।<br><br>
                                      <!-- details -->
                                      &nbsp;&nbsp;&nbsp;&nbsp;উপযুক্ত বিষয়ে সবিনয় নিবেদন এই যে, আমি/ আমরা {{ currentLocale === 'en' ? appDetail.company_name_en : appDetail.company_name_bn }} নিবন্ধন নং {{ appDetail.broker_registration_no }}। নিম্নের ছকের বর্ণনা অনুযায়ী {{ $n(appDetail.tea_quantity) }} কেজি {{ getPortNames(appDetail.port_names_id) }} এর মাধ্যম {{ getExportCountryName(appDetail.export_country_id) }} রপ্তানি করতে ইচ্ছুক। উক্ত চা রপ্তানি লাইসেন্স প্রদানের জন্য অনুরোধ জানানো হলো। <br><br>
                                    </div>
                                    <div v-else>
                                      To,<br>
                                      Deputy Director (Commercial)<br>
                                      Bangladesh Tea Board<br>
                                      171-172, Bayezid Bostami Road,<br>
                                      Nasirabad, Chittagong.<br><br>
                                      <!-- subject -->
                                      Subject: Regarding application for license to export tea by auction. <br><br>
                                      <!-- details -->
                                      In respect of the above, I hereby declare that I/we are {{ currentLocale === 'en' ? appDetail.company_name_en : appDetail.company_name_bn }} Registration No. {{ appDetail.broker_registration_no }}. {{ getExportCountryName(appDetail.export_country_id) }} is willing to export {{ $n(appDetail.tea_quantity) }} kg through {{ getPortNames(appDetail.port_names_id) }} port as per the table below. A request has been made for the export license of the said tea. <br><br>
                                    </div>
                                    <!-- details data show -->
                                    <b-table-simple hover small bordered>
                                      <b-thead>
                                      <b-tr>
                                          <b-th>{{ $t('teaGardenPanel.sell_no') }}</b-th>
                                          <b-th>{{ $t('teaGardenConfig.garden_name') }}</b-th>
                                          <b-th>{{ $t('teaGardenPanel.lot_no') }}</b-th>
                                          <b-th>{{ $t('teaGardenConfig.grade_name') }}</b-th>
                                          <b-th>{{ $t('teaGardenPanel.package') }}</b-th>
                                          <b-th>{{ $t('teaGardenPanel.weight') }} ({{ $t('globalTrans.kg') }})</b-th>
                                          <b-th>{{ $t('teaGardenPanel.auction_price') }} ({{ $t('globalTrans.tk_sign') }})</b-th>
                                          <b-th>{{ $t('teaGardenPanel.export_price') }} ({{ $t('globalTrans.tk_sign') }})</b-th>
                                      </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                          <template v-if="appDetail.details && Object.keys(appDetail.details).length > 0">
                                              <b-tr v-for="(item, index) in appDetail.details" :key="index">
                                                  <!-- sell no -->
                                                  <b-td>
                                                      <b-col>
                                                      {{ item.sell_no }}
                                                      </b-col>
                                                  </b-td>
                                                  <!-- garden name -->
                                                  <b-td>
                                                      {{ getGardenName(item.garden_id) }}
                                                  </b-td>
                                                  <!-- lot not -->
                                                  <b-td>
                                                      {{ item.lot_no }}
                                                  </b-td>
                                                  <!-- tea grade name -->
                                                  <b-td>
                                                      {{ getTeaGradeName(item.made_tea_grage_id) }}
                                                  </b-td>
                                                  <!-- package -->
                                                  <b-td>
                                                      {{ $n(item.package) }}
                                                  </b-td>
                                                  <!-- weight -->
                                                  <b-td>
                                                  {{ $n(item.weight) }}
                                                  </b-td>
                                                  <!-- auction price -->
                                                  <b-td>
                                                      {{ $n(item.auction_price) }}
                                                  </b-td>
                                                  <!-- export price -->
                                                  <b-td>
                                                      {{ $n(item.export_price) }}
                                                  </b-td>
                                              </b-tr>
                                              <b-tr>
                                                  <td colspan="6">{{ $t('globalTrans.total') }}</td>
                                                  <td>{{ $n(appDetail.total_auction_price) }}</td>
                                                  <td>{{ $n(appDetail.total_export_price) }}</td>
                                              </b-tr>
                                          </template>
                                          <template v-else>
                                              <b-tr>
                                                  <b-td colspan="8" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                  </b-tr>
                                          </template>
                                      </b-tbody>
                                    </b-table-simple>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col md="12" class="text-right">
                            <b-button variant="primary " class="btn-sm" @click="back()">{{ $t('globalTrans.back') }}</b-button>
                          </b-col>
                        </b-row>
                      </div>
                    </b-overlay>
                  </b-tab>
                  <b-tab :title="$t('license_management.forward_history')" v-if="!isExternalUser && forwardsShow">
                    <div v-if="forwarLoad || loading" class="col-md-12">
                      <forward-loading></forward-loading>
                    </div>
                    <div v-else class="row">
                      <div class="col-md-10 offset-md-1">
                        <div v-for="(forward, index) in appDetail.application.forwards" :key="index">
                          <b-card>
                            <b-card-title>
                              <h5 style="font-size:17px">{{ getCardTitle(forward) }}</h5>
                            </b-card-title>
                            <b-card-sub-title style="font-size:14px">
                              <span class="badge badge-success">{{ $t('license_management.sender') }}</span> : {{
                              getUserName(forward.sender_id) }} <i class=" ml-3 ri-arrow-right-line"></i>
                              <span class="badge badge-primary ml-3">{{ $t('license_management.receiver') }}</span> : {{
                              getUserName(forward.receiver_id) }}
                            </b-card-sub-title>
                            <b-card-text class="mt-3">
                              <b>{{ $t('globalTrans.note') }}</b> : <span>{{ $i18n.locale === 'bn' ? forward.remarks_bn : forward.remarks
                                }}</span>
                            </b-card-text>
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab :title="$t('teaGardenPanel.recommendation_history')" v-if="!isExternalUser && recommendationShow">
                    <div v-if="forwarLoad || loading" class="col-md-12">
                      <recommendation-loading></recommendation-loading>
                    </div>
                    <div v-else class="row">
                      <div class="col-md-10 offset-md-1">
                        <div v-for="(recommendation, index) in appDetail.application.recommendations" :key="index">
                          <b-card v-if="recommendation.status !== 1">
                            <b-card-title>
                              <h5 style="font-size:17px">{{ getCardTitleRecommendation(recommendation.status) }}</h5>
                            </b-card-title>
                            <b-card-text class="mt-3">
                              <p class="mb-2"><span class="badge badge-success">{{ $t('teaGardenPanel.by') }}</span> : {{
                                  getUserName(recommendation.user_id) }}</p>
                              <p class="mb-2"><strong>{{ $t('globalTrans.designation') }} : </strong>
                              {{ getDesignationName(recommendation.designation_id) }}</p>
                              <p v-if="forward.sub_designation_id">
                                <strong>{{ $t('organogram.sub_designation') }} : </strong>
                                {{ getSubDesignationName(forward.sub_designation_id) }}</p>
                              <p class="mb-2"><strong>{{ $t('globalTrans.office') }} : </strong>
                                {{ getOfficeName(recommendation.office_id) }}</p>
                              <b>{{ $t('globalTrans.note') }}</b> : <span>{{ $i18n.locale === 'bn' ? recommendation.remarks : recommendation.remarks
                                }}</span>
                            </b-card-text>
                            <div v-if="recommendation.attachment" class="text-right">
                              <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(recommendation.attachment) ? 'storage/' : '') + recommendation.attachment"><button
                                style="padding: 2px 10px;" class="btn btn-success btn-sm"><i class="ri-download-2-line"></i> {{
                                $t('globalTrans.download') }}</button></a>
                            </div>
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </template>
      </body-card>
    </card>
</template>
<script>
import RestApi, { authServiceBaseUrl, teaGardenServiceBaseUrl } from '@/config/api_config'
import ForwardLoading from './loading/ForwardLoading.vue'
import Loading from './loading/Details.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Details',
  components: {
    Loading,
    ForwardLoading
  },
  data () {
    return {
      loading: false,
      forwarLoad: false,
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      appDetail: {},
      forwardsShow: false,
      recommendationShow: false,
      users: []
    }
  },
  created () {
    this.appDetail = this.getAppDetail()
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    isExternalUser () {
      let isExternal = false
      if (this.$store.state.Auth.authUser.user_type === 2) {
        isExternal = true
      }
      return isExternal
    },
    recommendStatusList () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Recommended' : 'প্রস্তাবিত', text_en: 'Recommended', text_bn: 'প্রস্তাবিত' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Reject' : 'বাতিল', text_en: 'Reject', text_bn: 'বাতিল' }
      ]
      return list
    },
    currentLocale () {
      return this.$i18n.locale
    },
    appId () {
      return this.$route.params.id
    },
    stepList () {
      const setp = [
        { value: 2, text: this.$i18n.locale === 'en' ? 'Pending' : 'অপেক্ষমাণ', text_en: 'Pending', text_bn: 'অপেক্ষমাণ' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Recommendation' : 'সুপারিশ', text_en: 'Recommendation', text_bn: 'সুপারিশ' },
        { value: 5, text: this.$i18n.locale === 'en' ? 'Recommended' : 'প্রস্তাবিত', text_en: 'Recommended', text_bn: 'প্রস্তাবিত' },
        { value: 6, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদিত', text_en: 'Approved', text_bn: 'অনুমোদিত' },
        { value: 7, text: this.$i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত', text_en: 'Rejected', text_bn: 'প্রত্যাখ্যাত' }
      ]
      if (this.appDetail.application.status === 7) {
        return setp.filter(item => item.value !== 6)
      } else {
        return setp.filter(item => item.value !== 7)
      }
    },
    madeTeaGardeList () {
      if (this.authUser.user_type === 2) {
        return this.$store.state.ExternalUserService.teaGarden.commonObj.masterMadeTeaGradeList.filter(item => item.status === 1)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterMadeTeaGradeList.filter(item => item.status === 1)
      }
    },
    getGardenList () {
      if (this.authUser.user_type === 2) {
        return this.$store.state.ExternalUserService.teaGarden.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
      } else {
        return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
      }
    },
    portNameList () {
        return [
          { value: 1, text: this.$i18n.locale === 'bn' ? 'চট্টগ্রাম নৌ বন্দর' : 'Chattogram Sea Port' },
          { value: 2, text: this.$i18n.locale === 'bn' ? 'মংলা নৌ বন্দর' : 'Mongla Sea Port' },
          { value: 3, text: this.$i18n.locale === 'bn' ? 'হযরত শাহজালাল আন্তর্জাতিক বিমান বন্দর' : 'Hazrat Shahjalal International Airport' },
          { value: 4, text: this.$i18n.locale === 'bn' ? 'শাহ্ আমানাত আন্তর্জাতিক বিমান বন্দর' : 'Shah Amanat International Airport' },
          { value: 5, text: this.$i18n.locale === 'bn' ? 'ওসমানী আন্তর্জাতিক বিমান বন্দর' : 'Osmani International Airport' },
          { value: 6, text: this.$i18n.locale === 'bn' ? 'কক্সবাজার আন্তর্জাতিক বিমান বন্দর' : "Cox's Bazar International Airport" }
        ]
    }
  },
  methods: {
    getStatusName (status) {
      return status === 2 ? this.$t('dealer.reject') + ' ' + this.$t('globalTrans.note') : this.$t('dealer.resubmit') + ' ' + this.$t('globalTrans.note')
    },
    getCardTitle (tcb) {
      const tcbStep = this.$store.state.LicenseRegistrationService.commonObj.tcbStepList.find(item => item.value === parseInt(tcb.step_id))
      if (typeof tcbStep !== 'undefined') {
        return this.$i18n.locale === 'bn' ? tcbStep.text_bn : tcbStep.text_en
      } else {
        return ''
      }
    },
    back () {
      this.$router.go(-1)
    },
    async getAppDetail () {
        this.loading = true
        const result = await RestApi.getData(teaGardenServiceBaseUrl, '/btb/license-application/export-license-show' + '/' + this.appId)
        if (result.success) {
          this.appDetail = result.data
            if (this.appDetail.application.forwards.length) {
              this.forwardsShow = true
              this.getForwardedUsers(result.data.application.forwards)
            }
            if (this.appDetail.application.recommendations.length) {
              this.recommendationShow = true
            }
        }
        this.loading = false
    },
    getOfficeName (id) {
      const data = this.$store.state.CommonService.commonObj.officeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    },
    getUserName (userId) {
      const user = this.users.find(user => parseInt(user.value) === parseInt(userId))
      return typeof user !== 'undefined' ? (this.$i18n.locale === 'bn' ? user.text_bn : user.text_en) : ''
    },
    async getForwardedUsers (forwards) {
      this.forwarLoad = true
      var userIds = []
      forwards.map((item, key) => {
        userIds.push(item.sender_id)
        userIds.push(item.receiver_id)
      })
      const params = Object.assign({}, { user_ids: userIds })
      await RestApi.getData(authServiceBaseUrl, '/user-detail-by-user-ids', params).then(response => {
          if (response.length) {
            this.users = response
          } else {
            this.users = []
          }
          this.forwarLoad = false
      })
    },
    getCardTitleRecommendation (status) {
      const tfaStepList = this.recommendStatusList.find(item => item.value === parseInt(status))
      if (typeof tfaStepList !== 'undefined') {
        return this.$i18n.locale === 'bn' ? tfaStepList.text_bn : tfaStepList.text_en
      } else {
        return ''
      }
    },
    getDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.designationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    async pdfExport () {
      this.loading = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.appId })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 34)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, '/btb/license-application/export-license-view-pdf', params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      this.loading = false
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getTeaGradeName (id) {
        const obj = this.madeTeaGardeList.find(item => item.value === id)
        if (obj) {
            return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
        }
    },
    getGardenName (id) {
        const obj = this.getGardenList.find(item => item.value === id)
        if (obj) {
            return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
        }
    },
    getExportCountryName (id) {
      const obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === id)
      if (obj) {
            return this.$i18n.locale === 'bn' ? obj?.text_bn : obj?.text_en
      }
    },
    getPortNames (ids) {
      if (Array.isArray(ids)) {
        var text = []
        ids.forEach(id => {
          const obj = this.portNameList.find(item => item.value === parseInt(id))
          if (obj) {
            var value = obj?.text
            text.push(value)
          }
        })
        return text.join(', ')
      }
    },
    getSubDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.subDesignationList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
<style scoped>
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }
  .tag {
    font-weight: 600;
    border-left: 4px solid #1c4261;
    padding-left: 6px;
  }
  .download-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 2px 4px;
  }
  .yes-btn {
    color: green;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
  }
  .no-btn {
    color: red;
    border: 1px solid gray;
    margin-left: 5px;
    font-size: 18px;
    padding: 1px 3px;
    margin-right: 33px !important;
  }

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
    }

    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }

    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
.tagTwo {
  position: relative;
  display: inline-block;
  border-radius: 6px;
  clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
  background: hsl(250deg, 100%, 40%);
  padding: 5px 25px;
  font-weight: 600;
  font-size: 12px;
  color: #FFF;
  transition: clip-path 500ms;
}
.tagTwo:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: hsl(252deg, 100%, 60%);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 6px 0;
  transition: transform 500ms;
}
</style>
