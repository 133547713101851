<template>
    <div>
        <slot v-if="this.$route.params.service_id === '1'">
            <tea-garden-application-view></tea-garden-application-view>
        </slot>
        <slot v-else-if="this.$route.params.service_id === '2'">
            <TreeDisposalNocView></TreeDisposalNocView>
        </slot>
        <slot v-else-if="this.$route.params.service_id === '3'">
            <small-tea-farmer-application-view></small-tea-farmer-application-view>
        </slot>
        <slot v-else-if="this.$route.params.service_id === '4'">
            <tea-factory-details></tea-factory-details>
        </slot>
        <slot v-else-if="this.$route.params.service_id === '18'">
            <ExGardenDetails />
        </slot>
        <slot v-else-if="this.$route.params.service_id === '30'">
            <BrokerDetails />
        </slot>
        <slot v-else-if="this.$route.params.service_id === '31'">
            <BlenderDetails />
        </slot>
        <slot v-else-if="this.$route.params.service_id === '32'">
            <BidderDetails />
        </slot>
        <slot v-else-if="this.$route.params.service_id === '33'">
            <RetailerDetails />
        </slot>
        <slot v-else-if="this.$route.params.service_id === '34'">
            <WareHouseDetails />
        </slot>
        <slot v-else-if="this.$route.params.service_id === '35'">
            <ExportLicenseDetails />
        </slot>
    </div>
</template>
<script>
import SmallTeaFarmerApplicationView from './SmallTeaFarmer/Details.vue'
import TeaGardenApplicationView from './TeaGarden/Details.vue'
import TreeDisposalNocView from './TreeDisposalNoc/Details.vue'
import TeaFactoryDetails from './TeaFactory/Details.vue'
import ExGardenDetails from './ExGarden/Details.vue'
import BlenderDetails from './License/BlenderDetails/Details.vue'
import BidderDetails from './License/BidderDetails/Details.vue'
import BrokerDetails from './License/BrokerDetails/Details.vue'
import RetailerDetails from './License/RetailerDetails/Details.vue'
import WareHouseDetails from './License/WearhouseDetails/Details.vue'
import ExportLicenseDetails from './License/ExportDetails/Details.vue'
export default {
  name: 'ApplicationDetails',
  components: {
    SmallTeaFarmerApplicationView,
      TeaGardenApplicationView,
      TreeDisposalNocView,
      TeaFactoryDetails,
      ExGardenDetails,
      RetailerDetails,
      BrokerDetails,
      BidderDetails,
      BlenderDetails,
      WareHouseDetails,
      ExportLicenseDetails
  },
  data () {
    return {
    }
  },
  computed: {
    appId () {
      return this.$route.params.id
    }
  }
}
</script>
